import PrimaryButton from '@/components/atoms/Button/PrimaryButton'
import Space from '@/components/atoms/Space'
import AnalysisYearSelector from '@/components/molecules/SelectorGroup/analysisYearSelect'
import AllScopes from '@/components/template/Analysis/Overview/AllScopes'
import Scope3 from '@/components/template/Analysis/Overview/Scope3'
import Scopes1and2 from '@/components/template/Analysis/Overview/Scopes1and2'
import DateSelector from '@/components/template/Analysis/Selector/DateSelector'
import { withLayout } from '@/components/template/Layout'
import SimpleTabs, { SimpleTabProps } from '@/components/template/SimpleTabs'
import { makeMonth, organizationApi, organizationSalesApi, organizationSummaryApi } from '@/ghgApi'
import useAnalysis from '@/hooks/useAnalysis'
import {
    AnnualOrganizationSummary,
    HalfYearOrganizationSummary,
    MonthlyOrganizationSummary,
    MonthOrganizationSummary,
    MonthSiteSummary,
    Objective,
    OrganizationSales,
    QuarterlyOrganizationSummary,
    SiteSales,
    YearSummaryItem,
} from '@/openapi/api'
import { ROUTES } from '@/routes'
import theme from '@/theme'
import useStore from '@/zustand/sotre'
import { makeStyles } from '@material-ui/core'
import * as FileSaver from 'file-saver'
import { navigate } from 'gatsby'
import React, { useEffect, useRef, useState, CSSProperties } from 'react'
import { useReactToPrint } from 'react-to-print'

export type CompareResponseData = AnnualOrganizationSummary | HalfYearOrganizationSummary | QuarterlyOrganizationSummary

export type ResponseData = CompareResponseData | MonthlyOrganizationSummary
export type TAllData = {
    dataByYear: {
        summaryItemList: YearSummaryItem[],
        salesList: OrganizationSales[] | SiteSales[],
    },
    dataByMonth: MonthOrganizationSummary | MonthSiteSummary
}

// ====================================================================================
// Tabs
// ====================================================================================

// ====================================================================================
// Overview
// ====================================================================================

const useStyles = makeStyles({
    content: {
        position: 'relative',
    },
    overlayRoot: {
        position: 'absolute',
        textAlign: 'center',
        background: '#0000005C 0% 0% no-repeat padding-box',
        opacity: 0.8,
        top: 0,
        width: '1600px',
        height: '3430px',
        zIndex: 10,
    },
    overlayLead: {
        color: '#FFFFFF',
        fontSize: '20px',
        fontWeight: 'bold',
        marginTop: '230px',
    },
    overlayButton: {
        color: '#FFFFFF',
        fontSize: '16px',
        marginTop: '30px',
    },
})

const styles: { [key: string]: CSSProperties } = {
    loading: {
        marginTop: 30,
    },
}

const OverviewInner = () => {
    const { storeState, analysisState, tabValue, disabledScope3, expandedMenu, setAnalysisState, setTabValue } =
        useStore()
    const [responseData, setResponseData] = useState<ResponseData>()
    const [objective, setObjective] = useState<Objective>()
    const [csvData, setCsvData] = useState<Blob>()
    const [isPrinting, setIsPrinting] = useState(false)
    const { setInitialAnalysisState } = useAnalysis()
    const [resDataChartCompare, setResDataChartCompare] = useState<Array<CompareResponseData>>([])
    const [yearSelectorHeight, setYearSelectorHeight] = useState<number>(0)
    const [allData, setAllData] = useState<TAllData>()
    const [showOverlay, setShowOverlay] = useState(false)
    const organizationId = storeState.organization?.id
    const dateString = analysisState.date.month
        ? `${analysisState.date.year}年${analysisState.date.month}月`
        : `${analysisState.date.year}年度`
    const componentRef = useRef<HTMLDivElement | null>(null)
    const promiseResolveRef = useRef<any>(null)
    const classes = useStyles()

    useEffect(() => {
        if (!organizationId) return
        if (analysisState.date.month) {
            const year =
                (storeState.organization?.startMonth as number) === 1 ||
                analysisState.date.month >= analysisState.dateSelector.options.months[0]
                    ? analysisState.date.year
                    : analysisState.date.year + 1
            organizationSummaryApi
                .getMonthlyOrganizationSummary(makeMonth(year, analysisState.date.month))
                .then((res) => setResponseData(res.data))
                .catch((err) => {
                    if (err.response?.status != 404) console.warn('OrganizationApi.getOrganizationSummaries:', err)
                })
        }
        if (analysisState.date.half) {
            const resDataEnd = organizationSummaryApi
                .getHalfYearOrganizationSummary(analysisState.date.year, analysisState.date.half)
                .then((res) => res.data)
            const resDataStart = organizationSummaryApi
                .getHalfYearOrganizationSummary(analysisState.date.compareYear, analysisState.date.half)
                .then((res) => res.data)

            Promise.all([resDataEnd, resDataStart]).then((data) => {
                setResponseData(data[0])
                setResDataChartCompare(data)
            })
        }
        if (analysisState.date.quarter) {
            const resDataEnd = organizationSummaryApi
                .getQuarterlyOrganizationSummary(analysisState.date.year, analysisState.date.quarter)
                .then((res) => res.data)
            const resDataStart = organizationSummaryApi
                .getQuarterlyOrganizationSummary(analysisState.date.compareYear, analysisState.date.quarter)
                .then((res) => res.data)

            Promise.all([resDataEnd, resDataStart]).then((data) => {
                setResponseData(data[0])
                setResDataChartCompare(data)
            })
        }
        if (
            analysisState.date.month === null &&
            analysisState.date.half === null &&
            analysisState.date.quarter === null &&
            analysisState.date.year
        ) {
            const resDataEnd = organizationSummaryApi
                .getAnnualOrganizationSummary(analysisState.date.year)
                .then((res) => res.data)
            const resDataStart = organizationSummaryApi
                .getAnnualOrganizationSummary(analysisState.date.compareYear)
                .then((res) => res.data)
            Promise.all([resDataEnd, resDataStart]).then((data) => {
                setResponseData(data[0])
                setResDataChartCompare(data)
            })
        }
        setAnalysisState({
            ...analysisState,
            detailCategoryId: undefined,
            detailCategoryTitle: undefined,
        })
    }, [
        analysisState.date.month,
        analysisState.date.year,
        analysisState.date.half,
        analysisState.date.quarter,
        // TODO: improved data compare year (not need calling api)
        analysisState.date.compareYear,
        organizationId,
    ])

    useEffect(() => {
        const getAllData = async () => {
            if (!storeState.organization) {
                console.warn("organization is empty.")
                return
            }
            const organizationId = storeState.organization.id
            const res = await Promise.all([
                organizationSummaryApi.getYearOrganizationSummary(),
                organizationSummaryApi.getMonthOrganizationSummary(),
                organizationSalesApi.getOrganizationSummarySales()
            ])
            const sortedYearSummaryItemList: YearSummaryItem[] = []
            const sortedSalesList: OrganizationSales[] = []
            analysisState.dateSelector.options.years.forEach((year) => {
                const processYearSummaryItem = res[0].data.years.find((d) => d.year === year)
                if (processYearSummaryItem) {
                    sortedYearSummaryItemList.unshift(processYearSummaryItem)
                } else {
                    sortedYearSummaryItemList.unshift({ year, quantity: 0, scopes: [] })
                }
                const processSales = res[2].data.salesList.find((s) => s.year === year)
                if (processSales) {
                    sortedSalesList.unshift(processSales)
                } else {
                    sortedSalesList.unshift({ organizationId: organizationId, year: year, sales: 0, productionVolume: 0 })
                }
            })
            setAllData({ dataByYear: { summaryItemList: sortedYearSummaryItemList, salesList: sortedSalesList }, dataByMonth: res[1].data })
            if (!(sortedYearSummaryItemList.find((item) => item.quantity !== 0))) {
                setShowOverlay(true)
            }
        }
        if (analysisState.dateSelector.options.years.length) getAllData()
    }, [analysisState.dateSelector.options.years])

    useEffect(() => {
        if (analysisState.date.year)
            organizationApi
                .getOrganizationObjectives()
                .then((res) => {
                    const objectiveFromApi = res.data.objectives.find(
                        (objective) => objective.year == analysisState.date.year,
                    ) || { year: analysisState.date.year, text: '' }
                    setObjective(objectiveFromApi)
                })
                .catch((err) => {
                    if (err.response?.status != 404) console.warn('OrganizationApi.getOrganizationObjectives:', err)
                })
    }, [storeState.organization, analysisState.date.year])

    useEffect(
        () => () => {
            if (storeState.organization?.startMonth) {
                setInitialAnalysisState(storeState.organization.startMonth)
                setTabValue(0)
            }
        },
        [],
    )

    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
            promiseResolveRef.current()
        }
    }, [isPrinting])

    const handlePrint = useReactToPrint({
        pageStyle: `@media print {
                 @page {
                   size: 450mm 600mm;
                   margin: 20;
                 }
               }`,
        content: () => componentRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve
                setIsPrinting(true)
            })
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null
            setIsPrinting(false)
        },
    })

    const tabs: SimpleTabProps[] = [
        {
            label: '全Scope',
            content:
                responseData && resDataChartCompare.length && allData ? (
                    <AllScopes
                        date={dateString}
                        apiData={responseData}
                        resDataChartCompare={resDataChartCompare}
                        allData={allData}
                        ref={componentRef}
                        setCsvData={setCsvData}
                        isPrinting={isPrinting}
                        isOrganization
                        objective={objective}
                    />
                ) : (
                    <div style={styles.loading}>loading...</div>
                ),
        },
        {
            label: 'Scope1',
            content:
                responseData && resDataChartCompare.length && allData ? (
                    <Scopes1and2
                        scope={1}
                        date={dateString}
                        apiData={responseData}
                        resDataChartCompare={resDataChartCompare}
                        allData={allData}
                        ref={componentRef}
                        setCsvData={setCsvData}
                        isPrinting={isPrinting}
                        isOrganization
                        objective={objective}
                    />
                ) : (
                    <div style={styles.loading}>loading...</div>
                ),
        },
        {
            label: 'Scope2',
            content:
                responseData && resDataChartCompare.length && allData ? (
                    <Scopes1and2
                        scope={2}
                        date={dateString}
                        apiData={responseData}
                        resDataChartCompare={resDataChartCompare}
                        allData={allData}
                        ref={componentRef}
                        setCsvData={setCsvData}
                        isPrinting={isPrinting}
                        isOrganization
                        objective={objective}
                    />
                ) : (
                    <div style={styles.loading}>loading...</div>
                ),
        },
        {
            label: 'Scope3',
            content:
                responseData && resDataChartCompare.length && allData ? (
                    <Scope3
                        date={dateString}
                        apiData={responseData}
                        resDataChartCompare={resDataChartCompare}
                        allData={allData}
                        ref={componentRef}
                        setCsvData={setCsvData}
                        isPrinting={isPrinting}
                        isOrganization
                        objective={objective}
                    />
                ) : (
                    <div style={styles.loading}>loading...</div>
                ),
            disabled: disabledScope3,
        },
    ]

    const handleCsvCick = () => {
        if (csvData) FileSaver.saveAs(csvData, `analysis-data-table-scope.csv`)
        else console.warn('no data to download')
    }

    const stylesFixed = {
        width: `calc(100% - ${expandedMenu ? 300 : 120}px)`,
        transition: 'width 0.225s cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    }

    return (
        <main>
            <title>データ分析（企業全体）｜ScopeX</title>
            <div
                style={{
                    position: 'fixed',
                    zIndex: 20,
                    top: 70,
                    ...stylesFixed,
                }}
            >
                <Space />
                <AnalysisYearSelector
                    objective={objective}
                    onCsvClick={handleCsvCick}
                    onPdfClick={handlePrint}
                    setYearSelectorHeight={setYearSelectorHeight}
                />
                <DateSelector />
                <Space height={15} />
            </div>

            <div className={classes.content} style={{ marginTop: 20 }}>
                <SimpleTabs
                    tabs={tabs}
                    value={tabValue}
                    tabFixed={{
                        position: 'fixed',
                        top: yearSelectorHeight + 150,
                        zIndex: 2,
                        backgroundColor: theme.colors.white,
                        ...stylesFixed,
                    }}
                    contentSpaceTop={yearSelectorHeight + 70}
                />
                <div className={classes.overlayRoot} style={showOverlay ? {} : { display: 'none' }}>
                    <div className={classes.overlayLead}>
                        まだデータが登録されていません。<br />データを入力して分析しましょう。
                    </div>
                    <PrimaryButton
                        className={classes.overlayButton}
                        width={130}
                        height={38}
                        fontSize={16}
                        onClick={() => { navigate(ROUTES.INPUT_MONTH) }}
                    >
                        データ入力
                    </PrimaryButton>
                </div>
            </div>
        </main>
    )
}

const Overview = () => <OverviewInner />

export default withLayout(Overview)
